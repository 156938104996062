/* =================== CHAMADA HOME =================== */
jQuery(document).ready(function ($) {
  if ($(".home-chamada").length > 0) {
    const chamadaHeight = $(".home-chamada").outerHeight();
    $(".footer").css("margin-bottom", chamadaHeight);
    // Close chamada
    $(".chamada-close").on("click", function () {
      $(".home-chamada").addClass("close");
      $(".footer").css("margin-bottom", "0");
    });
  }
});
